import React, { useState, useEffect, useRef } from 'react';
import type { NextPage } from 'next';
import { useRouter } from 'next/router';
import {
  EmailAuthPanel,
  PasswordAuthPanel,
  ProfileAuthPanel,
  GeneralAuthPanel,
  BioAuthPanel,
  FinalAuthPanel,
} from 'components/Auth/';
import SEO from 'components/SEO';

const NUM_PANELS = 6;

const Auth: NextPage = () => {
  const router = useRouter();
  const { activation_id, activation_token } = router.query;

  const [currentPanel, setCurrentPanel] = useState(0);

  // user values across all auth panels
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [pronouns, setPronouns] = useState('');
  const [birthdate, setBirthdate] = useState('');
  const [handle, setHandle] = useState('');

  const authWrapper = useRef<HTMLDivElement>(null);

  const moveForward = () => {
    if (currentPanel !== 6) {
      setCurrentPanel((prev) => prev + 1);
    }
  };

  const moveBack = () => {
    if (currentPanel !== 0) {
      setCurrentPanel((prev) => prev - 1);
    }
  };

  const moveTo = (panelNumber: number) => {
    setCurrentPanel(panelNumber);
  };

  // state handlers to send into auth panels rather than the direct setState functions
  const setCurrentEmail = (email: string) => setEmail(email);
  const setCurrentName = (name: string) => setName(name);
  const setCurrentPassword = (password: string) => setPassword(password);
  const setCurrentPronouns = (pronouns: string) => setPronouns(pronouns);
  const setCurrentBirthdate = (birthdate: string) => setBirthdate(birthdate);
  const setCurrentHandle = (handle: string) => setHandle(handle);

  useEffect(() => {
    // handle madlibs style panel animation
    if (authWrapper && authWrapper.current) {
      authWrapper.current.style.transform = `translateY(-${
        (currentPanel / NUM_PANELS) * 100
      }%)`;
    }
  }, [currentPanel]);

  useEffect(() => {
    if (!!activation_id && !!activation_token) {
      const emailInStorage = localStorage.getItem('email');

      if (emailInStorage) {
        setCurrentEmail(emailInStorage ? emailInStorage : '');
        moveTo(2);
      } else {
        moveTo(0);
      }
    }
  }, [activation_id, activation_token]);

  return (
    <div>
      <SEO />
      <main className="w-screen h-screen max-h-screen-mobile relative overflow-hidden">
        {/* The use of h-600 class is due to NUM_PANELS being 6, change this via tailwind.config if the number of panels change */}
        <div
          className="w-full h-600 transform duration-500 ease-out"
          ref={authWrapper}
        >
          <EmailAuthPanel
            secondaryButtonAction={moveBack}
            primaryButtonAction={moveTo}
            onScreen={currentPanel === 0}
            setCurrentEmail={setCurrentEmail}
          />
          <PasswordAuthPanel
            secondaryButtonAction={moveBack}
            onScreen={currentPanel === 1}
            currentEmail={email}
          />
          <ProfileAuthPanel
            secondaryButtonAction={moveTo}
            primaryButtonAction={moveForward}
            onScreen={currentPanel === 2}
            setCurrentName={setCurrentName}
            setCurrentPassword={setCurrentPassword}
            setCurrentHandle={setCurrentHandle}
          />
          <GeneralAuthPanel
            secondaryButtonAction={moveBack}
            primaryButtonAction={moveForward}
            onScreen={currentPanel === 3}
            setCurrentPronouns={setCurrentPronouns}
            setCurrentBirthdate={setCurrentBirthdate}
          />
          <BioAuthPanel
            secondaryButtonAction={moveBack}
            primaryButtonAction={moveForward}
            onScreen={currentPanel === 4}
            currentEmail={email}
            currentName={name}
            currentPassword={password}
            currentBirthdate={birthdate}
            currentPronouns={pronouns}
            currentHandle={handle}
            activationId={
              !!activation_id ? (activation_id as string) : undefined
            }
            activationToken={
              !!activation_token ? (activation_token as string) : undefined
            }
          />
          <FinalAuthPanel
            onScreen={currentPanel === 5}
            currentName={name}
            currentHandle={handle}
          />
        </div>
      </main>
    </div>
  );
};

export default Auth;
